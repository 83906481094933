
    import Glide from '@glidejs/glide';

    export default {
        name: 'AnimatedLoop',

        props: {
            direction: {
                type: String,
                default: 'ltr',
            },
            items: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                animatedLoopGlide: null,
            };
        },

        mounted() {
            if (this.items.length) {
                this.glideAnimation();
            }
        },

        beforeDestroy() {
            if (this.animatedLoopGlide instanceof Glide) {
                this.animatedLoopGlide.destroy();
            }
        },

        methods: {
            glideAnimation() {
                this.animatedLoopGlide = new Glide(this.$refs.glide, {
                    type: 'carousel',
                    autoplay: true,
                    animationDuration: 9000,
                    animationTimingFunc: 'linear',
                    perView: 8,
                    direction: this.direction,
                    gap: 16,
                    breakpoints: {
                        577: {
                            perView: 2,
                        },

                        821: {
                            perView: 3,
                        },

                        1201: {
                            perView: 4,
                        },

                        1921: {
                            perView: 6,
                        },
                    },
                });

                this.animatedLoopGlide.mount();
            },
        },
    };
