
    /* eslint-disable */
    import LazyHydrate from 'vue-lazy-hydration';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faArrowRight as fasArrowRight,
    } from '@fortawesome/pro-solid-svg-icons/faArrowRight';
    import { imgResize } from '@/utils/helpers';
    import AnimatedLoop from '@/components/Utils/Animates/AnimatedLoop';
    import JobTabsScroll from '@/components/Job/JobTabsScroll';
    import GetJobMatchesOnboarding from '@/components/Utils/GetJobMatchesOnboarding';

    export default {
        name: 'CareerOneHome',

        components: {
            LazyHydrate,
            C1Splash: () => import(/* webpackChunkName: "C1Splash" */'@/components/Utils/C1Splash'),
            AnimatedLoop,
            JobTabsScroll,
            GetJobMatchesOnboarding,
            FontAwesomeIcon,
        },

        data() {
            return {
                employerItemsTop: [
                    {
                        name: 'ALDI',
                        image: require('@/assets/img/top-employers/aldi.png'),
                    },
                    {
                        name: 'Tesla',
                        image: require('@/assets/img/top-employers/tesla.png'),
                    },
                    {
                        name: 'CBA',
                        image: require('@/assets/img/top-employers/cba.jpeg'),
                    },
                    {
                        name: 'Telstra',
                        image: require('@/assets/img/top-employers/telstra.png'),
                    },
                    {
                        name: 'Kmart',
                        image: require('@/assets/img/top-employers/kmart.png'),
                    },
                    {
                        name: 'Macquarie Group',
                        image: require('@/assets/img/top-employers/macquarie-group.jpeg'),
                    },
                    {
                        name: 'Nine',
                        image: require('@/assets/img/top-employers/nine.png'),
                    },
                    {
                        name: 'Metcash',
                        image: require('@/assets/img/top-employers/metcash.png'),
                    },
                    {
                        name: 'Glencore',
                        image: require('@/assets/img/top-employers/glencore.jpeg'),
                    },
                ],
                employerItemsBottom: [
                    {
                        name: 'Bupa',
                        image: require('@/assets/img/top-employers/bupa.png'),
                    },
                    {
                        name: 'Canva',
                        image: require('@/assets/img/top-employers/canva.png'),
                    },
                    {
                        name: 'Google',
                        image: require('@/assets/img/top-employers/google.png'),
                    },
                    {
                        name: 'ABC',
                        image: require('@/assets/img/top-employers/ABC.png'),
                    },
                    {
                        name: 'NRMA',
                        image: require('@/assets/img/top-employers/NRMA.png'),
                    },
                    {
                        name: 'OPSM',
                        image: require('@/assets/img/top-employers/OPSM.png'),
                    },
                    {
                        name: 'Healthscope',
                        image: require('@/assets/img/top-employers/Healthscope.png'),
                    },
                    {
                        name: 'Grill\'d',
                        image: require('@/assets/img/top-employers/grilld.png'),
                    },
                    {
                        name: 'Brandbank',
                        image: require('@/assets/img/top-employers/brandbank.png'),
                    },
                    {
                        name: 'Strikeforce',
                        image: require('@/assets/img/top-employers/strikeforce.png'),
                    },
                ],
                showAnimatedLoop: true,
                showPromptModal: false,
                // showHomeSplash: false,
                loginPrompt: {
                    component_name: 'LoginPrompt',
                    layout: 'sm',
                    code: 'LOGIN_PROMPT',
                    page_referral_element: '',
                    site_position: 'login_signup_popup',
                    title: 'Login or sign up',
                    options: {
                        employer_site: false,
                    },
                },
                icons: {
                    fasArrowRight,
                },
            };
        },

        mounted() {
            this.resetSearchFilterModal();

            document.addEventListener('visibilitychange', () => {
                this.showAnimatedLoop = !document.hidden;
            });
        },

        methods: {
            imageResize(url, w, h) {
                return url ? imgResize(url, w, h) : '';
            },

            resetSearchFilterModal() {
                document.body.classList.remove('body--with-fullscreen-modal');
            },

            openPromptModal(pageReferralElement) {
                this.loginPrompt.page_referral_element = pageReferralElement;
                this.showPromptModal = true;
            },

            closePromptModal() {
                this.showPromptModal = false;
            },
        },
    };
