
    import { mapState } from 'vuex';

    export default {
        name: 'JobTabsScroll',

        data() {
            return {
                isMenuOpen: false,
                categories: [],
            };
        },

        computed: {
            ...mapState({
                $_appInfo: state => state.appInfo,
            }),

            selectedCategory() {
                const cat = this.categories.filter(o => o.is_selected);
                return cat.length ? cat[0] : {};
            },
        },

        created() {
            this.getFooterLinks();
        },

        methods: {
            selectCategory(category) {
                this.categories.filter((obj) => {
                    const isSelected = obj.id === category.id;
                    this.$set(obj, 'is_selected', isSelected);
                    return isSelected;
                });

                if (this.$_appInfo.isMobile) {
                    this.close();
                }
            },

            getFooterLinks() {
                this.$store.dispatch('common/getFooterLinks').then((response) => {
                    this.categories = response.data;
                    this.$set(this.categories[0], 'is_selected', true);
                });
            },

            toggle() {
                this.isMenuOpen = !this.isMenuOpen;
            },

            open() {
                this.isMenuOpen = true;
            },

            close() {
                this.isMenuOpen = false;
            },

            beforeEnter(el) {
                el.style.top = '100%';
            },

            enter(el, done) {
                if (this.$_appInfo.isMobile) {
                    this.$velocity(el, 'slideDown', { easing: 'easeInOutQuart', duration: 700, complete: done });
                }
            },

            leave(el, done) {
                if (this.$_appInfo.isMobile) {
                    this.$velocity(el, 'slideUp', {
                        easing: 'easeInOutQuart',
                        duration: 700,
                        complete() {
                            el.display = 'flex';
                            done();
                        },
                    });
                }
            },
        },
    };
