
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import {
        faArrowRight as fasArrowRight,
    } from '@fortawesome/pro-solid-svg-icons/faArrowRight';

    export default {
        name: 'GetJobMatchesOnboarding',

        components: {
            FontAwesomeIcon,
        },

        props: {
            pageReferral: {
                type: String,
                default: 'switch_now_button',
            },
        },

        data() {
            return {
                showPromptModal: false,
                loginPrompt: {
                    component_name: 'LoginPrompt',
                    layout: 'sm',
                    code: 'LOGIN_PROMPT',
                    page_referral_element: 'onboarding_popup',
                    site_position: 'onboarding_popup',
                    options: {
                        employer_site: false,
                    },
                },
                icons: {
                    fasArrowRight,
                },
            };
        },

        created() {
            this.loginPrompt.page_referral_element = this.pageReferral;
        },

        methods: {
            openPromptModal() {
                this.showPromptModal = true;
            },

            closePromptModal() {
                this.showPromptModal = false;
            },
        },
    };
